import {RECEIVE_NOTIFICATIONS} from '../actions/notifications'

const initialState = {}


export default function notifications(state = initialState, action) {
    switch (action.type) {
        case RECEIVE_NOTIFICATIONS :
            return action.notifications;
        default :
            return state
    }
}
