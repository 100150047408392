import firebase from "firebase";
import "firebase/firestore";

const config = {
    apiKey: "AIzaSyCaLwN6N6OD4MxBwP3YZ9m0yYp1FR6Wg7U",
    authDomain: "vacatube-mvp.firebaseapp.com",
    projectId: "vacatube-mvp",
    storageBucket: "vacatube-mvp.appspot.com",
    messagingSenderId: "1041566396436",
    appId: "1:1041566396436:web:0241a7cb9d65e502b4b701",
    measurementId: "G-VNZ303L7CC"

};

firebase.initializeApp(config);
firebase.firestore = firebase.firestore();
firebase.storage = firebase.storage();
firebase.analytics = firebase.analytics();

export default firebase;
