import React, {Component, Suspense} from 'react';
import firebase from "../firebase/Firebase"
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {receiveUser} from "../actions/user";
import {receiveAuth} from "../actions/auth";
import {receiveCompany} from "../actions/company";

class AuthProvider extends Component {

    state = {
        redirect: null
    }

    async componentDidMount() {
        await firebase.auth().onAuthStateChanged(user => {
            if (user != null) {
                this.loadData();
                this.props.dispatch(receiveAuth({authenticated: true, uid: user.uid}))
            } else {
                this.props.dispatch(receiveAuth({authenticated: false, uid: ""}))
            }
        });
    }

    async loadData() {
        let uid = firebase.auth().currentUser.uid;
        let userListener = firebase.firestore.collection("users").doc(uid)
        let observerUser = await userListener.onSnapshot(docSnapshot => {
            let user = docSnapshot.data();
            if (user !== undefined) {
                this.props.dispatch(receiveUser(user))
                localStorage.setItem(
                    'user',
                    JSON.stringify(user)
                );
                if (user.type === "company") {
                    let company = firebase.firestore.collection("companies").doc(user.companyId)
                    let observerCompany = company.onSnapshot(docSnapshot => {
                        let company = docSnapshot.data();
                        this.props.dispatch(receiveCompany(company))
                    });
                }
            }
        }, err => {
            console.log(err)
        });
    }


    Loader = () => {
        return (
            <div id="preloader">
                <div id="status">
                    <div className="spinner">
                        <div className="double-bounce1"></div>
                        <div className="double-bounce2"></div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <Suspense fallback={this.Loader()}>
                </Suspense>
            </React.Fragment>

        )

    }
}

function mapStateToProps({user}) {
    return {
        user,
    }
}

export default withRouter(connect(mapStateToProps)(AuthProvider))

