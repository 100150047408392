import {RECEIVE_INDEXES} from '../actions/indexes'

const initialState = {currentJobsPage:0,currentApplicationsPage: 0 }


export default function indexes(state = initialState, action) {
    switch (action.type) {
        case RECEIVE_INDEXES :
            return action.indexes;
        default :
            return state
    }
}
