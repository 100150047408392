import {RECEIVE_FIRSTTIME} from '../actions/firstTime'


const initialState = {firstTime: false, popUp: true};


export default function firstTime(state = initialState, action) {
    switch (action.type) {
        case RECEIVE_FIRSTTIME :
            return action.firstTime;
        default :
            return state
    }
}
