import React, {Component} from 'react';
import {Alert, Button, Col, Container, Form, FormGroup, Input, Label, Progress, Row} from "reactstrap";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {_updateApplicantInfo, _updateCompanyContactInfo, _updateCompanyInfo} from "../../firebase/helpers";
import firebase from "../../firebase/Firebase";
import pen from "../../images/icon/pen.svg";
//Import components
import '../PrivacyPolicy/privacy.scss';
import './editprofile.scss';

const src = {pen};


class EditCompany extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyName: '',
            companyAddress: '',
            numberOfEmployees: '',
            sector: '',
            companyAbout: '',
            companyEmail: '',
            companyWebsite: '',
            successMsg: false,
            successMsg2: false,
            successMsg3: false,
            userType: this.props.user.type,
            avatar: '',
            coverImage: '',
            name: '',
            firstName: '',
            address: '',
            email: '',
            telNumber: '',
            currentJob: '',
            aboutMe: '',
            image: null,
            url: "",
            urlAvatar: '',
            src: '',
            cropResult: '',
            linkedIn: '',
            birthday: '',
            progress: 0,
            progressAvatar: 0,
            companyNumber: '',
            videoUrl: '',
            recordedVideo: '',
            videoIsUploaded: '',
            modalVideo: false,
            buttonAppears: false,
            progressVideo: 0,
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmit2 = this.handleSubmit2.bind(this);
        this.handleSubmit3 = this.handleSubmit3.bind(this);
        this.cropImage = this.cropImage.bind(this);
        this.onChange = this.onChange.bind(this);
        this.useDefaultImage = this.useDefaultImage.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({successMsg: true});
    }

    handleSubmit2(event) {
        event.preventDefault();
        this.setState({successMsg2: true});
    }

    handleSubmit3(event) {
        event.preventDefault();
        this.setState({successMsg3: true});
    }


    receiveCompany() {
        const companyId = this.props.user.companyId;
        firebase.firestore.collection("companies").doc(companyId).onSnapshot(doc => {
            const company = doc.data();
            this.setState({
                companyName: company.companyName,
                companyAddress: company.companyAddress,
                numberOfEmployees: company.numberOfEmployees,
                companyAbout: company.companyAbout,
                sector: company.sector,
                companyEmail: company.companyEmail,
                companyWebsite: company.companyWebsite,
                url: company.cover,
                urlAvatar: company.avatar,
                companyNumber: company.companyNumber,
            })
        }, err => {
            console.log(err)
        });
    }

    receiveApplicant() {

        const applicant = this.props.user;

        this.setState({
            name: applicant.name,
            firstName: applicant.firstName,
            address: applicant.address,
            email: applicant.email,
            telNumber: applicant.telNumber,
            currentJob: applicant.currentJob,
            aboutMe: applicant.aboutMe,
            url: applicant.cover,
            urlAvatar: applicant.avatar,
            linkedIn: applicant.linkedIn,
            birthday: applicant.birthday,
            videoUrl: applicant.videoUrl,
            recordedVideo: applicant.recordedVideo,
        })

    }


    componentDidMount() {
        if (this.props.user.type === "company") {
            this.receiveCompany()
        } else {
            this.receiveApplicant()
        }
        document.body.classList = "";
        window.addEventListener("scroll", this.scrollNavigation, true);
    }

    // Make sure to remove the DOM listener when the component is unmounted.
    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollNavigation, true);
    }

    scrollNavigation = () => {
        var doc = document.documentElement;
        var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        if (top > 80) {
            document.getElementById('topnav').classList.add('nav-sticky');
        } else {
            document.getElementById('topnav').classList.remove('nav-sticky');
        }
    }
    uploadCover = e => {
        if (e.target.files[0]) {
            const image = e.target.files[0];
            const uploadTask = firebase.storage.ref(`images/${image.name}`).put(image);
            uploadTask.on(
                "state_changed",
                snapshot => {
                    // progress function ...
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    this.setState({progress: progress});
                },
                error => {
                    // Error function ...
                    console.log(error);
                },
                () => {
                    // complete function ...
                    firebase.storage
                        .ref("images")
                        .child(image.name)
                        .getDownloadURL()
                        .then(url => {
                            this.setState({url});
                            {
                                this.props.user.type === 'applicant' ? firebase.firestore.collection("users").doc(this.props.user.docId).update({
                                        cover: url
                                    })
                                    :
                                    firebase.firestore.collection("companies").doc(this.props.user.companyId).update({
                                        cover: url
                                    })
                            }
                        });
                }
            );

        }
    };
    uploadAvatar = e => {
        if (e.target.files[0]) {
            const image = e.target.files[0];
            const uploadTask = firebase.storage.ref(`images/${image.name}`).put(image);
            uploadTask.on(
                "state_changed",
                snapshot => {
                    // progress function ...
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    this.setState({progressAvatar: progress});
                },
                error => {
                    // Error function ...
                    console.log(error);
                },
                () => {
                    // complete function ...
                    firebase.storage
                        .ref("images")
                        .child(image.name)
                        .getDownloadURL()
                        .then(urlAvatar => {
                            this.setState({urlAvatar});
                            {
                                this.props.user.type === 'applicant' ? firebase.firestore.collection("users").doc(this.props.user.docId).update({
                                        avatar: urlAvatar
                                    })
                                    :
                                    firebase.firestore.collection("companies").doc(this.props.user.companyId).update({
                                        avatar: urlAvatar
                                    })
                            }

                        });
                }
            );

        }
    };

    uploadVideo = e => {
        if (e.target.files[0]) {
            const video = e.target.files[0];
            const uploadTask = firebase.storage.ref(`videos/${video.name}`).put(video);
            uploadTask.on(
                "state_changed",
                snapshot => {
                    // progress function ...
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    this.setState({progressVideo: progress});
                },
                error => {
                    // Error function ...
                    console.log(error);
                },
                () => {
                    // complete function ...
                    firebase.storage
                        .ref("videos")
                        .child(video.name)
                        .getDownloadURL()
                        .then(url => {
                            this.setState({videoUrl: url, videoIsUploaded: true});
                        });
                }
            );

        }
    };


    updateCompanyInfo() {
        console.log("==== saving")
        _updateCompanyInfo(this.props.user.companyId, this.state.companyName, this.state.companyAddress, this.state.sector, this.state.numberOfEmployees, this.state.companyAbout, this.state.companyNumber)
        _updateCompanyContactInfo(this.props.user.companyId, this.state.companyWebsite, this.state.companyEmail)
        this.props.history.push(`/vacatube-company/${this.props.user.companyId}`)
    }


    onChange(e) {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            this.setState({src: reader.result});
        };
        reader.readAsDataURL(files[0]);
    }

    cropImage() {
        if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
            return;
        }
        this.setState({
            cropResult: this.cropper.getCroppedCanvas().toDataURL(),
        });
    }

    useDefaultImage() {
        this.setState({src});
    }

    togglemodalVideo = () => {
        this.setState(prevState => ({
            modalVideo: !prevState.modalVideo
        }));
    }

    retake = () => {
        this.setState({videoIsUploaded: false});
        this.setState({progress: 0})
    }

    deleteVideo = () => {
        this.setState({videoIsUploaded: false})
        this.setState({videoUrl: ""})
        this.setState({recordedVideo: ""})
    }

    uploadRecordedVideo = video => {
        let date = new Date();
        let timestamp = date.getTime();
        timestamp = timestamp.toString()
        const uploadTask = firebase.storage.ref(`videos/${timestamp}`).put(video);
        uploadTask.on(
            "state_changed",
            snapshot => {
                // progress function ...
                const progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                this.setState({progress});
            },
            error => {
                // Error function ...
                console.log(error);
            },
            () => {
                // complete function ...
                firebase.storage
                    .ref("videos")
                    .child(timestamp)
                    .getDownloadURL()
                    .then(url => {
                        this.setState({recordedVideo: url, videoIsUploaded: true})
                        this.setState({buttonAppears: true})
                    });
            }
        );
    };

    render() {
        return (
            <React.Fragment>
                <section className="bg-half-170 bg-white d-table w-100">
                    <Container className='pt-2'>
                        <Row className="justify-content-center">
                            <Col lg="10">
                                <div className="sidebar rounded shadow-lg ">
                                    <div className="widget border-bottom p-4">
                                        <h4 className="mb-0 title-sans-black">Edit profile</h4>
                                    </div>
                                    <div className="p-4 bg-light">
                                        <div className="mt-3 text-md-left text-center d-sm-flex">
                                            <h5 className="text-md-left text-center title-sans-black-detail">Cover</h5>
                                        </div>
                                        {this.state.progress > 0 && this.state.progress < 100 ?
                                            <div className="progress-box pt-5">
                                                <Progress value={this.state.progress} color="primary"
                                                          barClassName="position-relative">
                                                    <div
                                                        className="progress-value d-block text-muted h6">{this.state.progress}%
                                                    </div>
                                                </Progress></div> :
                                            <div className="mt-3 text-md-left text-center d-sm-flex">
                                                <div className="work-container position-relative rounded">
                                                    {this.state.url !== "" ?
                                                        <img src={this.state.url} className="img-fluid rounded cover"
                                                             alt="work"/> :
                                                        <img src={'https://i.postimg.cc/gcLVsjyH/Banner-1200x600.png'}
                                                             className="img-fluid rounded cover" alt="work"/>}
                                                </div>
                                            </div>
                                        }
                                        <Row>
                                            <Col sm="12" className='pt-3'>
                                                <input ref={fileInput => this.fileInput = fileInput}
                                                       style={{display: 'none'}} type="file" onChange={this.uploadCover}
                                                       className="btn btn-primary"
                                                />
                                                <Button className="btn btn-pills btn-light w-25"
                                                        onClick={() => this.fileInput.click()}>Upload cover</Button>
                                            </Col>
                                        </Row>
                                        <h5 className="text-md-left title-sans-black-detail text-center pt-3">Avatar</h5>
                                        {this.state.progressAvatar > 0 && this.state.progressAvatar < 100 ?
                                            <div className="progress-box pt-5">
                                                <Progress value={this.state.progressAvatar} color="primary"
                                                          barClassName="position-relative">
                                                    <div
                                                        className="progress-value d-block text-muted h6">{this.state.progressAvatar}%
                                                    </div>
                                                </Progress></div> :
                                            <div className="mt-3 text-md-left text-center d-sm-flex">
                                                <div className="work-container position-relative rounded">
                                                    {this.state.urlAvatar !== "" ? <img src={this.state.urlAvatar}
                                                                                        className="avatar float-md-left avatar-medium mr-md-4 rounded-pill d-block photo"
                                                                                        alt=""/> :
                                                        <img src={"https://i.postimg.cc/76x6jBjk/Avatar-1500x1500.png"}
                                                             className="avatar float-md-left avatar-medium mr-md-4 rounded-pill d-block photo"
                                                             alt=""/>}
                                                </div>
                                            </div>}
                                        <Row>
                                            <Col sm="12" className='pt-3'>
                                                <input ref={fileInput1 => this.fileInput1 = fileInput1}
                                                       style={{display: 'none'}} type="file"
                                                       onChange={this.uploadAvatar}
                                                       className="btn btn-primary"
                                                />
                                                <Button className="btn btn-pills btn-light w-25"
                                                        onClick={() => this.fileInput1.click()}>
                                                    Upload avatar</Button>
                                            </Col>
                                        </Row>
                                        <Alert color="info" isOpen={this.state.successMsg} toggle={() => {
                                            this.setState({successMsg: !this.state.successMsg})
                                        }}>
                                            Update successful.
                                        </Alert>
                                        <h5 className="text-md-left title-sans-black-detail text-center pt-3 mt-3">Company
                                            information</h5>
                                        <Form>
                                            <Row className="mt-4">
                                                <Col md="6">
                                                    <FormGroup className="position-relative">
                                                        <Label>Company name</Label>
                                                        <i className="mdi mdi-domain ml-3 icons"></i>
                                                        <Input name="name" id="first" type="text"
                                                               className="form-control pl-5 bg-white"
                                                               placeholder={this.state.companyName}
                                                               value={this.state.companyName}
                                                               onChange={event => this.setState({companyName: event.target.value})}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup className="position-relative">
                                                        <Label>Address headquarters</Label>
                                                        <i className="mdi mdi-map-marker ml-3 icons"></i>
                                                        <Input name="name" id="last" type="text"
                                                               className="form-control pl-5 bg-white"
                                                               placeholder={this.state.companyAddress}
                                                               value={this.state.companyAddress}
                                                               onChange={event => this.setState({companyAddress: event.target.value})}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup className="position-relative">
                                                        <Label>Number of employees</Label>
                                                        <select value={this.state.numberOfEmployees}
                                                                onChange={event => this.setState({numberOfEmployees: event.target.value})}
                                                                className="form-control custom-select bg-white">
                                                            <option defaultValue="1">1</option>
                                                            <option value="2-9">2-9</option>
                                                            <option value="10-50">10-50</option>
                                                            <option value="51-100">51-100</option>
                                                            <option value="100+">100+</option>
                                                        </select>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup className="position-relative">
                                                        <Label>Industry</Label>
                                                        <i className="mdi mdi-factory ml-3 icons"></i>
                                                        <Input name="name" id="occupation" type="text"
                                                               className="form-control pl-5 bg-white"
                                                               placeholder={this.state.sector}
                                                               value={this.state.sector}
                                                               onChange={event => this.setState({sector: event.target.value})}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup className="position-relative">
                                                        <Label>Company number</Label>
                                                        <i className="mdi mdi-credit-card ml-3 icons"></i>
                                                        <Input name="name" id="occupation" type="text"
                                                               className="form-control pl-5 bg-white"
                                                               placeholder={this.state.companyNumber}
                                                               value={this.state.companyNumber}
                                                               onChange={event => this.setState({companyNumber: event.target.value})}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="12">
                                                    <FormGroup className="fposition-relative">
                                                        <Label>About us</Label>
                                                        <i className="mdi mdi-comment-text-outline ml-3 icons pl-3"></i>
                                                        <textarea name="comments" id="comments" rows="8"
                                                                  className="form-control pl-5 bg-white"
                                                                  placeholder={this.state.companyAbout}
                                                                  onChange={event => this.setState({companyAbout: event.target.value})}>{this.state.companyAbout}</textarea>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Form>
                                        <Row>
                                            <Col md="6" className="mt-3 pt-2">
                                                <h5 className='title-sans-black-detail'>Contact information</h5>
                                                <Alert color="info" isOpen={this.state.successMsg2} toggle={() => {
                                                    this.setState({successMsg2: !this.state.successMsg2})
                                                }}>
                                                    Update successful.
                                                </Alert>

                                                <Form onSubmit={this.handleSubmit2}>
                                                    <Row className="mt-4">
                                                        <Col lg="12">
                                                            <FormGroup className="position-relative">
                                                                <Label>Email</Label>
                                                                <i className="mdi mdi-email ml-3 icons"></i>
                                                                <Input name="email" id="email" type="email"
                                                                       className="form-control pl-5 bg-white"
                                                                       placeholder={this.state.companyEmail}
                                                                       value={this.state.companyEmail}
                                                                       onChange={event => this.setState({companyEmail: event.target.value})}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="12">
                                                            <FormGroup className="position-relative">
                                                                <Label>Website</Label>
                                                                <i className="mdi mdi-earth ml-3 icons"></i>
                                                                <Input name="text" id="text" type="text"
                                                                       className="form-control pl-5 bg-white"
                                                                       placeholder={this.state.companyWebsite}
                                                                       value={this.state.companyWebsite}
                                                                       onChange={event => this.setState({companyWebsite: event.target.value})}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Col>
                                        </Row>
                                        <Button onClick={() => this.updateCompanyInfo()} className="btn btn-pills btn-primary w-25">Save
                                            information</Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>

        );
    }
}

function mapStateToProps({user, jobs}) {
    return {
        user, jobs

    }
}

export default withRouter(connect(mapStateToProps)(EditCompany))