import {combineReducers} from 'redux'
import user from './user'
import company from './company'
import jobs from './jobs'
import {firebaseReducer} from "react-redux-firebase";
import auth from "./auth";
import apiStatusReducer from "./apiStatus";
import notifications from "./notifications";
import indexes from "./indexes";
import firstTime from "./firstTime";

export default combineReducers({
    user,
    company,
    jobs,
    firebaseReducer,
    auth,
    apiStatusReducer,
    notifications,
    indexes,
    firstTime
})
