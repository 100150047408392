import firebase from "./Firebase";

//hello
/**
 * Summary: create a applicant user in firebase collection users.
 *
 * @author Wall Street.
 *
 * @param {string} lastName.
 * @param {string} email.
 *
 *
 */
export function _createApplicant(email) {
    let uid = firebase.auth().currentUser.uid;
    firebase.firestore.collection("users").doc(uid)
        .set({
            type: "applicant",
            docId: uid,
            name: '',
            firstName: '',
            address: '',
            email,
            telNumber: '',
            currentJob: '',
            birthday: '',
            aboutMe: '',
            dark: false,
            completedProfile: false,
            linkedIn: "",
            avatar: '',
            cover: '',
            videoUrl: '',
            recordedVideo: '',
        })
        .catch(function (error) {
            console.log(error.message);
        })
}

/**
 * Summary: create a company user in firebase collection users and companies.
 *
 * @author Wall Street.
 *
 * @param {string} companyName.
 * @param {string} email.
 *
 *
 */
export function _createCompany(companyName, email) {
    let uid = firebase.auth().currentUser.uid;
    firebase.firestore.collection("companies").add({
        companyName,
        authors: [uid],
        companyAddress: '',
        sector: '',
        numberOfEmployees: '',
        companyAbout: '',
        companyEmail: email,
        companyNumber: '',
        image: '',
        dark: false,
        cover: '',
        avatar: '',
        credits: 1,
    }).then(function (docRef) {
        firebase.firestore.collection("users").doc(uid)
            .set({type: "company", email, docId: uid, companyId: docRef.id, completedProfile: false, freeJob: true})
            .catch(function (error) {
                console.log(error.message);
            })
        firebase.firestore.collection("companies").doc(docRef.id)
            .update({docId: docRef.id})
            .catch(function (error) {
                console.log(error.message);
            })
    })
        .catch(function (error) {
            console.error("Error adding document: ", error);
        });
}

/**
 * Summary: create a job.
 *
 * @author Wall Street.
 * @param {string} companyId.
 * @param {string} contractType.
 * @param {string} jobType.
 * @param {string} videoUrl.
 * @param {string} jobAddress.
 * @param {string} experience.
 * @param {string} fringeBenefits.
 * @param {string} salary.
 * @param {string} whoAreWe.
 * @param {string} whoWeAreLookingFor.
 * @param {string} whatDoWeExpect.
 * @param {string} whatDoWeOffer.
 *@param {string} region.
 *@param {string} discipline.
 *@param {boolean} active.
 * @param {Date} datePosted.
 * @param {string} companyName.
 * @param {string} companyEmail.
 * @param {string} image.
 * @param {string} linkPage.
 */
export function _createJob(companyId, contractType, jobType, videoUrl, jobAddress, experience, fringeBenefits, salary, whoAreWe, whoWeAreLookingFor, whatDoWeExpect, whatDoWeOffer, region, discipline, active, datePosted, companyName, companyEmail, image, recordedVideo, linkPage) {
    let uid = firebase.auth().currentUser.uid;
    firebase.firestore.collection("jobs").add({
        companyId,
        authors: [uid],
        contractType,
        jobType,
        videoUrl,
        jobAddress,
        experience,
        fringeBenefits,
        salary,
        whoAreWe,
        whoWeAreLookingFor,
        whatDoWeExpect,
        whatDoWeOffer,
        region,
        discipline,
        active,
        datePosted,
        companyName,
        companyEmail,
        image,
        recordedVideo,
        linkPage,
        filled: false,
    }).then(function (docRef) {
        firebase.firestore.collection("jobs").doc(docRef.id)
            .update({docId: docRef.id})
            .catch(function (error) {
                console.log(error.message);
            })
    })
        .catch(function (error) {
            console.error("Error adding document: ", error);
        });
}


/**
 * Summary: edit a job.
 *
 * @author Wall Street 2.
 * @param {string} docId.
 * @param {string} contractType.
 * @param {string} jobType.
 * @param {string} videoUrl.
 * @param {string} jobAddress.
 * @param {string} experience.
 * @param {string} fringeBenefits.
 * @param {string} salary.
 * @param {string} whoAreWe.
 * @param {string} whoWeAreLookingFor.
 * @param {string} whatDoWeExpect.
 * @param {string} whatDoWeOffer.
 *@param {string} region.
 *@param {string} discipline.
 * @param {boolean} active.
 */
export function _editJob(docId, contractType, jobType, videoUrl, jobAddress, experience, fringeBenefits, salary, whoAreWe, whoWeAreLookingFor, whatDoWeExpect, whatDoWeOffer, region, discipline, recordedVideo) {
    let uid = firebase.auth().currentUser.uid;
    firebase.firestore.collection("jobs").doc(docId).update({
        authors: [uid],
        contractType,
        jobType,
        videoUrl,
        jobAddress,
        experience,
        fringeBenefits,
        salary,
        whoAreWe,
        whoWeAreLookingFor,
        whatDoWeExpect,
        whatDoWeOffer,
        region,
        discipline,
        recordedVideo,
    }).catch(function (error) {
        console.error("Error adding document: ", error);
    });
}

/**
 * Summary: update company info.
 *
 * @author Wall Street.
 * @param {string} companyId.
 * @param {string} companyName.
 * @param {string} companyAddress.
 * @param {string} sector.
 * @param {string} numberOfEmployees.
 * @param {string} companyAbout.
 * @param {string} companyNumber.
 *
 *
 */
export function _updateCompanyInfo(companyId, companyName, companyAddress, sector, numberOfEmployees, companyAbout, companyNumber) {
    let uid = firebase.auth().currentUser.uid;
    firebase.firestore.collection("users").doc(uid)
        .update({
            completedProfile: true,
        })
        .catch(function (error) {
            console.log(error.message);
        })
    firebase.firestore.collection("companies").doc(companyId).update({
        companyId,
        companyName,
        companyAddress,
        sector,
        numberOfEmployees,
        companyAbout,
        companyNumber,
    }).catch(function (error) {
        console.error("Error adding document: ", error);
    });

}

/**
 * Summary: create a job.
 *
 * @author Wall Street.
 * @param {string} companyWebsite.
 * @param {string} companyEmail.

 *
 *
 */
export function _updateCompanyContactInfo(companyId, companyWebsite, companyEmail) {
    firebase.firestore.collection("companies").doc(companyId).update({
        companyWebsite,
        companyEmail,
    }).catch(function (error) {
        console.error("Error adding document: ", error);
    });
}

/**
 * Summary: create application.
 *
 * @author Wall Street.
 * @param {string} firstName.
 * @param {string} name.
 * @param {string} address.
 * @param {string} email.
 * @param {string} telNumber.
 * @param {string} currentJob.
 * @param {string} aboutMe.
 *  @param {string} linkedIn.
 *   @param {string} birthday.
 *   @param {string} videoUrl.
 *   @param {string} recordedVideo.
 *
 *
 */
export function _updateApplicantInfo(firstName, name, address, email, telNumber, currentJob, aboutMe, linkedIn, birthday, videoUrl, recordedVideo) {
    let uid = firebase.auth().currentUser.uid;
    firebase.firestore.collection("users").doc(uid).update({
        firstName,
        name,
        address,
        email,
        telNumber,
        currentJob,
        aboutMe,
        linkedIn,
        birthday,
        videoUrl,
        recordedVideo,
        completedProfile: true,

    }).catch(function (error) {
        console.error("Error adding document: ", error);
    });
}


/**
 * Summary: create application.
 *
 * @author Wall Street2.
 * @param {string} name.
 * @param {string} firstName.
 * @param {string} email.
 * @param {string} telNumber.
 * @param {string} motivation.
 * @param {string} companyId.
 * @param {string} docId.
 * @param {string} linkedIn.
 * @param {string} birthday
 * @param {string} applicantLocation.
 * @param {string} applicantAbout.
 * @param {string} applicantAvatar.
 * @param {string} currentJob.
 * @param {string} jobType.
 * @param {string} cvUrl.
 */
export function _apply(firstName, name, email, telNumber, motivation, companyId, docId, videoUrl, linkedIn, birthday, applicantLocation, applicantAbout, currentJob, applicantAvatar, jobType, cvUrl, applicantId, companyAuthorId) {
    let uid = firebase.auth().currentUser.uid;
    firebase.firestore.collection("applications").add({
        firstName,
        name,
        email,
        telNumber,
        motivation,
        companyId,
        linkedIn,
        birthday,
        applicantLocation,
        applicantAbout,
        applicantAvatar,
        currentJob,
        jobType,
        jobId: docId,
        authors: [uid],
        datePosted: new Date(),
        videoUrl,
        cvUrl,
        seen: false,
        applicantId,
        companyAuthorId
    }).then(function (docRef) {
        firebase.firestore.collection("applications").doc(docRef.id)
            .update({docId: docRef.id})
            .catch(function (error) {
                console.log(error.message);
            })
    })
        .catch(function (error) {
            console.error("Error adding document: ", error);
        });
}


/**
 * Summary: launch campaign.
 *
 * @author Wall Street2.
 * @param {string} budget.
 * @param {string} jobType.
 * @param {string} reach.
 * @param {string} applicants.
 * @param {string} clicks.
 * @param {Date} dateCreated.
 */
export function _createCampaign(budget, jobType, reach, applicants, clicks, dateCreated) {
    let uid = firebase.auth().currentUser.uid;
    firebase.firestore.collection("campaigns").add({
        budget,
        jobType,
        reach,
        applicants,
        clicks,
        dateCreated,
        authors: [uid],
    }).then(function (docRef) {
        firebase.firestore.collection("campaigns").doc(docRef.id)
            .update({docId: docRef.id})
            .catch(function (error) {
                console.log(error.message);
            })
    })
        .catch(function (error) {
            console.error("Error adding document: ", error);
        });
}


/**
 * Summary: subscribe.
 *
 * @author Wall Street2.
 * @param {string} email.
 */
export function _subscribe(email) {
    firebase.firestore.collection("subscriptions").add({
        email,
    }).then(function (docRef) {
        firebase.firestore.collection("subscriptions").doc(docRef.id)
            .update({docId: docRef.id})
            .catch(function (error) {
                console.log(error.message);
            })
    })
        .catch(function (error) {
            console.error("Error adding document: ", error);
        });
}

/**
 * Summary: subscribeBeta.
 * @author Wall Street2.
 * @param {string} firstName.
 * @param {string} lastName.
 * @param {string} email.
 */
export function _subscribeBeta(firstName, lastName, email) {
    firebase.firestore.collection("subscriptionsBeta").add({
        firstName,
        lastName,
        email,
    }).then(function (docRef) {
        firebase.firestore.collection("subscriptionsBeta").doc(docRef.id)
            .update({docId: docRef.id})
            .catch(function (error) {
                console.log(error.message);
            })
    })
        .catch(function (error) {
            console.error("Error adding document: ", error);
        });
}

/**
 * Summary: addCredits.
 * @author Wall Street2.
 * @param {string} companyId.
 * @param {number} credits.
 */
export function _addCredits(companyId, credits) {
    let ref = firebase.firestore.collection("companies").doc(companyId);
    ref.get().then(doc => {
        let company = doc.data();
        ref.update({
            credits: company.credits + credits,
        }).catch(function (error) {
            console.error("Error adding document: ", error);
        });
    })
}

/**
 * Summary: decreaseCredits.
 * @author Wall Street2.
 * @param {string} companyId.
 * @param {number} credits.
 */
export function _decreaseCredits(userId, credits) {
    let ref = firebase.firestore.collection("users").doc(userId);
    ref.get().then(doc => {
        let user = doc.data();
        ref.update({
            credits: user.credits - 1,
        }).catch(function (error) {
            console.error("Error adding document: ", error);
        });
    })
}


export function _createFeedback(uid, mail, question1, question2, question3, question4, question5) {
    firebase.firestore.collection("feedback").add({
        uid,
        mail,
        question1,
        question2,
        question3,
        question4,
        question5,
    })
        .catch(function (error) {
            console.error("Error adding document: ", error);
        });
}


export function _contact(companyName, companyMail, subject, message) {
    firebase.firestore.collection("contact").add({
        companyName,
        companyMail,
        subject,
        message,
    })
        .catch(function (error) {
            console.error("Error adding document: ", error);
        });
}