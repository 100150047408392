import React from 'react';
import JobsPublic from "./pages/JobsPublic";
import EditCompany from "./pages/EditCompany";
import EditApplicant from "./pages/EditApplicant";
import TestPage from "./pages/TestPage";

// Vacatube
const Main = React.lazy(() => import('./pages/Main'));
const Authentication = React.lazy(() => import('./pages/Authentication'));
const Jobs = React.lazy(() => import('./pages/Jobs/index'));
const JobDetail = React.lazy(() => import('./pages/JobDetail/index'));
const Company = React.lazy(() => import('./pages/Company/index'));
const EditProfile = React.lazy(() => import('./pages/EditProfile/index'));
const PostAJob = React.lazy(() => import('./pages/PostAJob/index'));
const EditJob = React.lazy(() => import('./pages/EditJob/index'));
const Boost = React.lazy(() => import('./pages/Boost/index'));
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy/index'));
const TermsOfService = React.lazy(() => import('./pages/TermsOfService/index'));
const Candidate = React.lazy(() => import('./pages/Candidate/index'));
const SingOut = React.lazy(() => import('./pages/Authentication/signout'));
const AboutUs = React.lazy(() => import('./pages/AboutUs/index'));
const Services = React.lazy(() => import('./pages/Services/index'));
const LandingCompany = React.lazy(() => import('./pages/LandingCompany/index'));
const LandingCandidate = React.lazy(() => import('./pages/LandingCandidate/index'));
const LandingProduct = React.lazy(() => import('./pages/LandingProduct/index'));
const Applications = React.lazy(() => import('./pages/Applications/index'));
const ApplicationDetail = React.lazy(() => import('./pages/ApplicationDetail/index'));
const JobPublic = React.lazy(() => import('./pages/JobPublic/index'));
const CompanyPublic = React.lazy(() => import('./pages/CompanyPublic/index'));
const CandidatePublic = React.lazy(() => import('./pages/CandidatePublic/index'));
const Checkout = React.lazy(() => import('./pages/Checkout/index'));
const Payment = React.lazy(() => import('./pages/Payment/index'));
const PaymentSuccess = React.lazy(() => import('./pages/PaymentSuccess/index'));
const PaymentFailed = React.lazy(() => import('./pages/PaymentFailed/index'));
const Blog = React.lazy(() => import('./pages/Blog/index'));
const BlogPage1 = React.lazy(() => import('./pages/BlogPages/BlogPage1'));
const BlogPage2 = React.lazy(() => import('./pages/BlogPages/BlogPage2'));
const BlogPage3 = React.lazy(() => import('./pages/BlogPages/BlogPage3'));
const BlogPage4 = React.lazy(() => import('./pages/BlogPages/BlogPage4'));
const BlogPage5 = React.lazy(() => import('./pages/BlogPages/BlogPage5'));
const BlogPage6 = React.lazy(() => import('./pages/BlogPages/BlogPage6'));
const BlogPage7 = React.lazy(() => import('./pages/BlogPages/BlogPage7'));
const BlogPage8 = React.lazy(() => import('./pages/BlogPages/BlogPage8'));
const BlogPage9 = React.lazy(() => import('./pages/BlogPages/BlogPage9'));
const BlogPage10 = React.lazy(() => import('./pages/BlogPages/BlogPage10'));
const Feedback = React.lazy(() => import('./pages/Feedback/index'));
const Contact = React.lazy(() => import('./pages/Contact/index'));
const Billing = React.lazy(() => import('./pages/Billing/index'));
const UserData = React.lazy(() => import('./pages/UserData/index'));
const Apply = React.lazy(() => import('./pages/Apply/index'));
const University = React.lazy(() => import('./pages/University/index'));
const SignIn = React.lazy(() => import('./pages/SignIn/index'));
const Register = React.lazy(() => import('./pages/Register/index'));

const routes = [
    //vacatube
    {path: '/vacatube-authentication', component: Authentication},
    {path: '/vacatube-jobs', component: Jobs},
    {path: '/vacatube-candidate', component: Candidate},
    {path: '/vacatube-edit-profile', component: EditProfile},
    {path: '/vacatube-company', component: Company},
    {path: '/vacatube-job-detail/:docId', component: JobDetail},
    {path: '/vacatube-post-a-job', component: PostAJob},
    {path: '/vacatube-about-us', component: AboutUs},
    {path: '/vacatube-checkout', component: Checkout},
    {path: '/vacatube-payment', component: Payment},
    {path: '/vacatube-payment-success', component: PaymentSuccess},
    {path: '/vacatube-payment-failed', component: PaymentFailed},
    {path: '/vacatube-services', component: Services},
    {path: '/vacatube-edit-job/:docId', component: EditJob},
    {path: '/vacatube-boost', component: Boost},
    {path: '/candidate', component: LandingCandidate},
    {path: '/company', component: LandingCompany},
    {path: '/product', component: LandingProduct},
    {path: '/vacatube-privacy-policy', component: PrivacyPolicy},
    {path: '/vacatube-terms-of-service', component: TermsOfService},
    {path: '/vacatube-loading', component: Main},
    {path: '/vacatube-signout', component: SingOut},
    {path: '/blog', component: Blog},
    {path: '/feedback', component: Feedback},
    {path: '/contact', component: Contact},
    {path: '/billing', component: Billing},
    {path: '/userdata', component: UserData},
    {path: '/apply/:docId', component: Apply},
    {path: '/university', component: University},
    {path: '/vacatube-blog-page1', component: BlogPage1},
    {path: '/vacatube-blog-page2', component: BlogPage2},
    {path: '/vacatube-blog-page3', component: BlogPage3},
    {path: '/vacatube-blog-page4', component: BlogPage4},
    {path: '/vacatube-blog-page5', component: BlogPage5},
    {path: '/vacatube-blog-page6', component: BlogPage6},
    {path: '/vacatube-blog-page7', component: BlogPage7},
    {path: '/vacatube-blog-page8', component: BlogPage8},
    {path: '/vacatube-blog-page9', component: BlogPage9},
    {path: '/vacatube-blog-page10', component: BlogPage10},
    {path: '/vacatube-applications', component: Applications},
    {path: '/vacatube-application-detail/:docId', component: ApplicationDetail},
    {path: '/p/:docId', component: JobPublic},
    {path: '/c/:docId', component: CompanyPublic},
    {path: '/t/:docId', component: CandidatePublic},
    {path: '/jobs-public', component: JobsPublic},
    {path: '/vacatube-edit-company', component: EditCompany},
    {path: '/vacatube-edit-applicant', component: EditApplicant},
    {path: '/sign-in', component: SignIn},
    {path: '/register', component: Register},
    {path: '/test-page', component: TestPage},
    //change component to root to have original
    {path: '/', component: Main, isWithoutLayout: true},


];

export default routes;