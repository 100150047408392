import React, {Component} from 'react';
import {Alert, Button, Col, Container, Form, FormGroup, Input, Label, Modal, Progress, Row} from "reactstrap";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {_updateApplicantInfo, _updateCompanyContactInfo, _updateCompanyInfo} from "../../firebase/helpers";
import firebase from "../../firebase/Firebase";
import pen from "../../images/icon/pen.svg";
//Import components
import '../PrivacyPolicy/privacy.scss';
import './editprofile.scss';
import '../PostAJob/camera.scss';
import VideoRecorder from "react-video-recorder";
import Actions from "../PostAJob/defaults/render-actions";
import ReactPlayer from "react-player";

const src = {pen};


class EditApplicant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyName: '',
            companyAddress: '',
            numberOfEmployees: '',
            sector: '',
            companyAbout: '',
            companyEmail: '',
            companyWebsite: '',
            successMsg: false,
            successMsg2: false,
            successMsg3: false,
            userType: this.props.user.type,
            avatar: '',
            coverImage: '',
            name: '',
            firstName: '',
            address: '',
            email: '',
            telNumber: '',
            currentJob: '',
            aboutMe: '',
            image: null,
            url: "",
            urlAvatar: '',
            src: '',
            cropResult: '',
            linkedIn: '',
            birthday: '',
            progress: 0,
            progressAvatar: 0,
            companyNumber: '',
            videoUrl: '',
            recordedVideo: '',
            videoIsUploaded: '',
            modalVideo: false,
            buttonAppears: false,
            progressVideo: 0,
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmit2 = this.handleSubmit2.bind(this);
        this.handleSubmit3 = this.handleSubmit3.bind(this);
        this.cropImage = this.cropImage.bind(this);
        this.onChange = this.onChange.bind(this);
        this.useDefaultImage = this.useDefaultImage.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({successMsg: true});
    }

    handleSubmit2(event) {
        event.preventDefault();
        this.setState({successMsg2: true});
    }

    handleSubmit3(event) {
        event.preventDefault();
        this.setState({successMsg3: true});
    }


    receiveCompany() {
        const companyId = this.props.user.companyId;
        firebase.firestore.collection("companies").doc(companyId).onSnapshot(doc => {
            const company = doc.data();
            this.setState({
                companyName: company.companyName,
                companyAddress: company.companyAddress,
                numberOfEmployees: company.numberOfEmployees,
                companyAbout: company.companyAbout,
                sector: company.sector,
                companyEmail: company.companyEmail,
                companyWebsite: company.companyWebsite,
                url: company.cover,
                urlAvatar: company.avatar,
                companyNumber: company.companyNumber,
            })
        }, err => {
            console.log(err)
        });
    }

    receiveApplicant() {

        const applicant = this.props.user;

        this.setState({
            name: applicant.name,
            firstName: applicant.firstName,
            address: applicant.address,
            email: applicant.email,
            telNumber: applicant.telNumber,
            currentJob: applicant.currentJob,
            aboutMe: applicant.aboutMe,
            url: applicant.cover,
            urlAvatar: applicant.avatar,
            linkedIn: applicant.linkedIn,
            birthday: applicant.birthday,
            videoUrl: applicant.videoUrl,
            recordedVideo: applicant.recordedVideo,
        })

    }


    componentDidMount() {
        if (this.props.user.type === "company") {
            this.receiveCompany()
        } else {
            this.receiveApplicant()
        }
        document.body.classList = "";
        window.addEventListener("scroll", this.scrollNavigation, true);
    }

    // Make sure to remove the DOM listener when the component is unmounted.
    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollNavigation, true);
    }

    scrollNavigation = () => {
        var doc = document.documentElement;
        var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        if (top > 80) {
            document.getElementById('topnav').classList.add('nav-sticky');
        } else {
            document.getElementById('topnav').classList.remove('nav-sticky');
        }
    }
    uploadCover = e => {
        if (e.target.files[0]) {
            const image = e.target.files[0];
            const uploadTask = firebase.storage.ref(`images/${image.name}`).put(image);
            uploadTask.on(
                "state_changed",
                snapshot => {
                    // progress function ...
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    this.setState({progress: progress});
                },
                error => {
                    // Error function ...
                    console.log(error);
                },
                () => {
                    // complete function ...
                    firebase.storage
                        .ref("images")
                        .child(image.name)
                        .getDownloadURL()
                        .then(url => {
                            this.setState({url});
                            {
                                this.props.user.type === 'applicant' ? firebase.firestore.collection("users").doc(this.props.user.docId).update({
                                        cover: url
                                    })
                                    :
                                    firebase.firestore.collection("companies").doc(this.props.user.companyId).update({
                                        cover: url
                                    })
                            }
                        });
                }
            );

        }
    };
    uploadAvatar = e => {
        if (e.target.files[0]) {
            const image = e.target.files[0];
            const uploadTask = firebase.storage.ref(`images/${image.name}`).put(image);
            uploadTask.on(
                "state_changed",
                snapshot => {
                    // progress function ...
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    this.setState({progressAvatar: progress});
                },
                error => {
                    // Error function ...
                    console.log(error);
                },
                () => {
                    // complete function ...
                    firebase.storage
                        .ref("images")
                        .child(image.name)
                        .getDownloadURL()
                        .then(urlAvatar => {
                            this.setState({urlAvatar});
                            {
                                this.props.user.type === 'applicant' ? firebase.firestore.collection("users").doc(this.props.user.docId).update({
                                        avatar: urlAvatar
                                    })
                                    :
                                    firebase.firestore.collection("companies").doc(this.props.user.companyId).update({
                                        avatar: urlAvatar
                                    })
                            }

                        });
                }
            );

        }
    };

    uploadVideo = e => {
        if (e.target.files[0]) {
            const video = e.target.files[0];
            const uploadTask = firebase.storage.ref(`videos/${video.name}`).put(video);
            uploadTask.on(
                "state_changed",
                snapshot => {
                    // progress function ...
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    this.setState({progressVideo: progress});
                },
                error => {
                    // Error function ...
                    console.log(error);
                },
                () => {
                    // complete function ...
                    firebase.storage
                        .ref("videos")
                        .child(video.name)
                        .getDownloadURL()
                        .then(url => {
                            this.setState({videoUrl: url, videoIsUploaded: true});
                        });
                }
            );

        }
    };


    updateCompanyInfo() {
        _updateCompanyInfo(this.props.user.companyId, this.state.companyName, this.state.companyAddress, this.state.sector, this.state.numberOfEmployees, this.state.companyAbout, this.state.companyNumber)
        _updateCompanyContactInfo(this.props.user.companyId, this.state.companyWebsite, this.state.companyEmail)
    }


    updateApplicantInfo() {
        _updateApplicantInfo(this.state.firstName, this.state.name, this.state.address, this.state.email, this.state.telNumber, this.state.currentJob, this.state.aboutMe, this.state.linkedIn, this.state.birthday, this.state.videoUrl, this.state.recordedVideo)
    }


    onChange(e) {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            this.setState({src: reader.result});
        };
        reader.readAsDataURL(files[0]);
    }

    cropImage() {
        if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
            return;
        }
        this.setState({
            cropResult: this.cropper.getCroppedCanvas().toDataURL(),
        });
    }

    useDefaultImage() {
        this.setState({src});
    }

    togglemodalVideo = () => {
        this.setState(prevState => ({
            modalVideo: !prevState.modalVideo
        }));
    }

    retake = () => {
        this.setState({videoIsUploaded: false});
        this.setState({videoUrl: ""})
        this.setState({recordedVideo: ""})
        this.setState({progressVideo: 0})
    }

    deleteVideo = () => {
        this.setState({videoIsUploaded: false})
        this.setState({videoUrl: ""})
        this.setState({recordedVideo: ""})
    }

    uploadRecordedVideo = video => {
        let date = new Date();
        let timestamp = date.getTime();
        timestamp = timestamp.toString()
        const uploadTask = firebase.storage.ref(`videos/${timestamp}`).put(video);
        uploadTask.on(
            "state_changed",
            snapshot => {
                // progress function ...
                const progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                this.setState({progress});
            },
            error => {
                // Error function ...
                console.log(error);
            },
            () => {
                // complete function ...
                firebase.storage
                    .ref("videos")
                    .child(timestamp)
                    .getDownloadURL()
                    .then(url => {
                        this.setState({recordedVideo: url, videoIsUploaded: true})
                        this.setState({buttonAppears: true})
                    });
            }
        );
    };

    render() {
        return (
            <React.Fragment>
                <section className="bg-half-170 bg-white d-table w-100">
                    <Container className='pt-2 '>
                        <Row className="justify-content-center">
                            <Col lg="10">
                                <div className="sidebar rounded shadow-lg">
                                    <div className="widget border-bottom p-4 ">
                                        <h5 className="mb-0 title-sans-black">Edit Profile</h5>
                                    </div>
                                    <div className="p-4 bg-light">
                                        <div className="mt-3 text-md-left text-center d-sm-flex">
                                            <h5>Cover</h5>
                                        </div>
                                        {this.state.progress > 0 && this.state.progress < 100 ?
                                            <div className="progress-box pt-5">
                                                <Progress value={this.state.progress} color="primary"
                                                          barClassName="position-relative">
                                                    <div
                                                        className="progress-value d-block text-muted h6">{this.state.progress}%
                                                    </div>
                                                </Progress></div> :
                                            <div className="mt-3 text-md-left text-center d-sm-flex cover">
                                                <div className="work-container position-relative rounded">
                                                    {this.state.url !== "" ?
                                                        <img src={this.state.url} className="img-fluid rounded cover"
                                                             alt="work"/> :
                                                        <img src={'https://i.postimg.cc/gcLVsjyH/Banner-1200x600.png'}
                                                             className="img-fluid rounded cover" alt="work"/>}
                                                </div>
                                            </div>}
                                        <Row>
                                            <Col sm="12" className='pt-3'>
                                                <input ref={fileInput2 => this.fileInput2 = fileInput2}
                                                       style={{display: 'none'}} type="file" onChange={this.uploadCover}
                                                       className="btn btn-primary"
                                                />
                                                <Button className="btn btn-pills btn-light w-25"
                                                        onClick={() => this.fileInput2.click()}>Upload cover</Button>
                                            </Col>
                                        </Row>
                                        <h5>Avatar</h5>
                                        {this.state.progressAvatar > 0 && this.state.progressAvatar < 100 ?
                                            <div className="progress-box pt-5">
                                                <Progress value={this.state.progressAvatar} color="primary"
                                                          barClassName="position-relative">
                                                    <div
                                                        className="progress-value d-block text-muted h6">{this.state.progressAvatar}%
                                                    </div>
                                                </Progress></div> :
                                            <div className="mt-3 text-md-left text-center d-sm-flex">
                                                <div className="work-container position-relative rounded">
                                                    {this.state.urlAvatar !== "" ? <img src={this.state.urlAvatar}
                                                                                        className="avatar float-md-left avatar-medium mr-md-4 rounded-pill d-block photo"
                                                                                        alt=""/> :
                                                        <img src={"https://i.postimg.cc/76x6jBjk/Avatar-1500x1500.png"}
                                                             className="avatar float-md-left avatar-medium mr-md-4 rounded-pill d-block photo"
                                                             alt=""/>}
                                                </div>
                                            </div>}
                                        <Row>
                                            <Col sm="12" className='pt-3'>
                                                <input ref={fileInput3 => this.fileInput3 = fileInput3}
                                                       style={{display: 'none'}} type="file"
                                                       onChange={this.uploadAvatar}
                                                       className="btn btn-primary"
                                                />
                                                <Button className="btn btn-pills btn-light w-25"
                                                        onClick={() => this.fileInput3.click()}>Upload avatar</Button>
                                            </Col>
                                        </Row>
                                        <Alert color="info" isOpen={this.state.successMsg} toggle={() => {
                                            this.setState({successMsg: !this.state.successMsg})
                                        }}>
                                            Update successful.
                                        </Alert>
                                        <h5>Personal information</h5>
                                        <Form>
                                            <Row className="mt-4">
                                                <Col md="6">
                                                    <FormGroup className="position-relative">
                                                        <Label>First Name</Label>
                                                        <i className="mdi mdi-account ml-3 icons"></i>
                                                        <Input name="name" id="first" type="text"
                                                               className="form-control pl-5 bg-white"
                                                               placeholder={this.state.firstName}
                                                               value={this.state.firstName}
                                                               onChange={event => this.setState({firstName: event.target.value})}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup className="position-relative">
                                                        <Label>Last Name</Label>
                                                        <i className="mdi mdi-account ml-3 icons"></i>
                                                        <Input name="name" id="first" type="text"
                                                               className="form-control pl-5 bg-white"
                                                               placeholder={this.state.name}
                                                               value={this.state.name}
                                                               onChange={event => this.setState({name: event.target.value})}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup className="position-relative">
                                                        <Label>Address</Label>
                                                        <i className="mdi mdi-map-marker ml-3 icons"></i>
                                                        <Input name="name" id="last" type="text"
                                                               className="form-control pl-5 bg-white"
                                                               placeholder={this.state.address === "" ? 'Address' : this.state.address}
                                                               value={this.state.address}
                                                               onChange={event => this.setState({address: event.target.value})}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup className="position-relative">
                                                        <Label>Email</Label>
                                                        <i className="mdi mdi-email ml-3 icons"></i>
                                                        <Input name="email" id="email" type="email"
                                                               className="form-control pl-5 bg-white"
                                                               placeholder={this.state.email === "" ? 'Email' : this.state.email}
                                                               value={this.state.email}
                                                               onChange={event => this.setState({email: event.target.value})}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup className="position-relative">
                                                        <Label>GSM Nr.</Label>
                                                        <i className="mdi mdi-phone ml-3 icons"></i>
                                                        <Input name="phone" id="phone" type="phone"
                                                               className="form-control pl-5 bg-white"
                                                               placeholder={this.state.telNumber === "" ? 'GSM Nr.' : this.state.telNumber}
                                                               value={this.state.telNumber}
                                                               onChange={event => this.setState({telNumber: event.target.value})}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup className="position-relative">
                                                        <Label>Current Job</Label>
                                                        <i className="mdi mdi-monitor ml-3 icons"></i>
                                                        <Input name="name" id="text" type="text"
                                                               className="form-control pl-5 bg-white"
                                                               placeholder={this.state.currentJob === "" ? 'Current job' : this.state.currentJob}
                                                               value={this.state.currentJob}
                                                               onChange={event => this.setState({currentJob: event.target.value})}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup className="position-relative">
                                                        <Label>Birthday</Label>
                                                        <i className="mdi mdi-cake ml-3 icons"></i>
                                                        <Input name="date" id="date" type="date"
                                                               className="form-control pl-5 bg-white"
                                                               placeholder={this.state.birthday}
                                                               value={this.state.birthday}
                                                               onChange={event => this.setState({birthday: event.target.value})}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup className="position-relative">
                                                        <Label>LinkedIn url</Label>
                                                        <i className="mdi mdi-linkedin ml-3 icons"></i>
                                                        <Input name="name" id="text" type="text"
                                                               className="form-control pl-5 bg-white"
                                                               placeholder={this.state.linkedIn === "" ? 'Linkedin url' : this.state.linkedIn}
                                                               value={this.state.linkedIn}
                                                               onChange={event => this.setState({linkedIn: event.target.value})}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="12">
                                                    <FormGroup className="fposition-relative">
                                                        <Label>Who am I?</Label>
                                                        <i className="mdi mdi-comment-text-outline ml-3 icons pl-3"></i>
                                                        <textarea name="comments" id="comments" rows="4"
                                                                  className="form-control pl-5 bg-white"
                                                                  placeholder={this.state.aboutMe}
                                                                  onChange={event => this.setState({aboutMe: event.target.value})}
                                                        >{this.state.aboutMe}</textarea>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="12">
                                                    <h5>One minute
                                                        pitch</h5>
                                                    {this.state.videoIsUploaded ?
                                                        <div>
                                                            <Row>
                                                                <Col xs="12" className="text-center mb-4 rounded">
                                                                    <div className='player-wrapper'>
                                                                        <ReactPlayer
                                                                            className='react-player'
                                                                            url={this.state.videoUrl.length > 0 ? this.state.videoUrl : this.state.recordedVideo}
                                                                            width='100%'
                                                                            height='100%'
                                                                            controls={true}
                                                                            playing={false}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <button className="btn btn-pills btn-light ml-2 mb-3"
                                                                      onClick={() => this.retake()}>Retake
                                                                    video</button>
                                                            </Row>
                                                        </div>
                                                        :
                                                        this.state.videoUrl !== "" || this.state.recordedVideo !== "" ?
                                                            <div>
                                                                <Row>
                                                                    <Col xs="12" className="text-center mb-4 rounded">
                                                                        <div className='player-wrapper'>
                                                                            <ReactPlayer
                                                                                className='react-player'
                                                                                url={this.state.videoUrl.length > 0 ? this.state.videoUrl : this.state.recordedVideo}
                                                                                width='100%'
                                                                                height='100%'
                                                                                controls={true}
                                                                                playing={false}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <button className="btn btn-pills btn-light ml-2 mb-3"
                                                                          onClick={() => this.retake()}>Retake
                                                                        video</button>
                                                                </Row>
                                                            </div>
                                                            :
                                                            <div>
                                                                <Row className='ml-1'>
                                                                    <h6 className='text-muted'>Show it, say it, send it.<br/>
                                                                        Tell who you are, what your background is and
                                                                        what you are looking for in a new job. Convince
                                                                        and stand out as a candidate. Upload or record
                                                                        the one minute pitch of your screen, cam or
                                                                        phone.</h6>
                                                                </Row>
                                                                <Row className='ml-1 pt-3 pb-1'>
                                                                    <FormGroup className="position-relative">
                                                                        <input
                                                                            ref={fileInput => this.fileInput = fileInput}
                                                                            type="file" accept="video/*"
                                                                            style={{display: 'none'}}
                                                                            onChange={this.uploadVideo}
                                                                            className="btn btn-primary"
                                                                        />
                                                                        <Button className="btn btn-pills btn-light"
                                                                                onClick={() => this.fileInput.click()}>Upload</Button>

                                                                    </FormGroup>
                                                                    <h5 className='pt-1 pl-2'>or </h5>
                                                                    <FormGroup className="position-relative pl-3">

                                                                        <Link onClick={() => this.togglemodalVideo()}
                                                                              className="btn btn-pills btn-light">record</Link>
                                                                    </FormGroup>
                                                                    <h5 className='pt-1 pl-2'>video</h5>
                                                                </Row>
                                                                {this.state.progressVideo > 0 ?
                                                                    <div className="progress-box pt-1 pb-2">
                                                                        <Progress value={this.state.progressVideo}
                                                                                  color="primary"
                                                                                  barClassName="position-relative">
                                                                            <div
                                                                                className="progress-value d-block text-muted h6">{this.state.progressVideo}%
                                                                            </div>
                                                                        </Progress></div> : null}
                                                            </div>}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="12">
                                                    <Link onClick={() => this.updateApplicantInfo()} to={{
                                                        pathname: `/vacatube-candidate/${this.props.user.docId}`,
                                                        state: {docId: this.props.user.docId}
                                                    }} className="btn btn-pills btn-primary mt-2 mr-2">Save profile</Link>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <Modal scrollable={true} isOpen={this.state.modalVideo}
                       id="trialform"
                       tabindex="1" className="video">
                    <VideoRecorder
                        isOnInitially
                        timeLimit={60000}
                        onRecordingComplete={videoBlob => {
                            // Do something with the video...
                            this.uploadRecordedVideo(videoBlob)
                        }}
                        renderActions={Actions}

                    />
                    <Row className="justify-content-center p-2">
                        <Col>
                            <Link onClick={() => this.setState({modalVideo: !this.state.modalVideo})}
                                  className="btn btn-outline-primary btn-block">Close</Link>
                        </Col>
                        <Col>
                            {this.state.buttonAppears === true ?
                                <Link onClick={() => this.togglemodalVideo()} className="btn btn-block btn-primary"
                                >Upload</Link> : null}
                        </Col>
                    </Row>

                </Modal>
            </React.Fragment>

        );
    }
}

function mapStateToProps({user, jobs}) {
    return {
        user, jobs

    }
}

export default withRouter(connect(mapStateToProps)(EditApplicant))