import React, { Component } from 'react';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import * as moment from 'moment'
import './jobcard.scss'

class JobCardPublic extends Component {

    generateDate(date) {
        return moment(new Date(date.seconds*1000)).format('DD/MM/YYYY')
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.props.jobs.map((job, key) =>
                        <Col lg="4" md="6" className="mt-4 pt-2" key={key} name="blog">
                            <Link to={{pathname: `/p/:${job.docId}`, state: {docId: job.docId}}}>
                                <div className="blog position-relative overflow-hidden shadow rounded bg-light">
                                    <div className="position-relative">
                                        {window.innerWidth < 700 ?
                                            job.image === "" ?
                                                <img src={"https://i.postimg.cc/gJv7Ls0n/Banner-3000x800.png"} className="image-container-web rounded-top"
                                                     alt=""/> :
                                                <img src={job.image} className="image-container-web rounded-top"
                                                     alt=""/> :
                                            job.image === "" ?
                                                <img src={"https://i.postimg.cc/gJv7Ls0n/Banner-3000x800.png"} className="image-container-web rounded-top"
                                                     alt=""/> :
                                                <img src={job.image} className="image-container-web rounded-top" />}
                                        <div className="overlay rounded-top bg-dark"></div>
                                    </div>
                                    <div className="content p-4">
                                        <h4><Link
                                            to={{
                                                pathname: `/p/:${job.docId}`,
                                                state: {docId: job.docId}
                                            }}
                                            className="title text-dark">{job.jobType}</Link></h4>
                                        <div className="post-meta mt-3">
                                            <Link to={{
                                                pathname: `/p/:${job.docId}`,
                                                state: {docId: job.docId}
                                            }}
                                                  className="text-muted float-right readmore">Watch<i
                                                className="mdi mdi-chevron-right"></i></Link>
                                            <ul className="list-unstyled mb-0">
                                                <li className="list-inline-item mr-2"><Link to="#"
                                                                                            className="text-muted like">{job.companyName}</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="author">
                                        <small className="text-light user d-block"><i
                                            className="mdi mdi-map-marker"></i> {job.jobAddress}</small>
                                        <small className="text-light date"><i
                                            className="mdi mdi-calendar-check"></i>{this.generateDate(job.datePosted)}
                                        </small>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                    )
                }
            </React.Fragment>
        );
    }
}

export default JobCardPublic;