import {RECEIVE_COMPANY} from '../actions/company'

const initialState = {credits: 0};

export default function connections(state = initialState, action) {
    switch (action.type) {
        case RECEIVE_COMPANY:
            return action.company;
        default :
            return state
    }
}
