// React Basic and Bootstrap
import React, {Component} from 'react';
import {Col, Container, Pagination, PaginationItem, PaginationLink, Row} from 'reactstrap';
//Import components
import Section from "./section";
import './jobs.scss';
// import images
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import firebase from "../../firebase/Firebase";
import {receiveIndexes} from "../../actions/indexes";
import JobCardPublic from "../../componentsVacatube/job-card-public";

let filterList = []
let jobsList = []

class JobsPublic extends Component {

    constructor(props) {
        super(props);
        this.state = {
            jobs: [],
            pathItems: [
                //id must required
                {id: 1, name: "Home", link: "/index"},
                {id: 2, name: "Pages", link: "#"},
                {id: 3, name: "Blog", link: "#"},
                {id: 4, name: "Blog Grid"},
            ],
            searchToggle: false,
            perPage: 9,
            currentPage: 0,
            totalPages: []
        }
    }

    receiveJobs() {
        this.setState({loader: true})
        let queryJob = firebase.firestore.collection("jobs").where("active", "==", true)
        queryJob.onSnapshot(querySnapshot => {
            const jobs = [];
            querySnapshot.forEach((doc) => {
                jobs.push(doc.data());
            });
            let totalPages = 1;
            if (jobs.length !== 0) {
                totalPages = Math.ceil(jobs.length / this.state.perPage)
            }
            if (totalPages !== 0) {
                totalPages = [...Array(totalPages).keys()]
            }
            let sortedJobs = jobs.sort((a, b) => (b.datePosted.seconds) - (a.datePosted.seconds))

            this.setState({jobs: sortedJobs, totalPages})
            this.setState({loader: false})
        }, err => {
            console.log(err)
        });
    }

    componentDidMount() {
        this.receiveJobs();

        document.body.classList = "";
        window.addEventListener("scroll", this.scrollNavigation, true);

        var jobs = document.getElementsByName("job");
        for (var i = 0; i < jobs.length; i++) {
            jobs[i].classList.remove("mt-4");
            jobs[i].classList.add("mb-4");
        }

    }

    // Make sure to remove the DOM listener when the component is unmounted.
    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollNavigation, true);
    }

    scrollNavigation = () => {
        var doc = document.documentElement;
        var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        if (top > 80) {
            document.getElementById('topnav').classList.add('nav-sticky');
        } else {
            document.getElementById('topnav').classList.remove('nav-sticky');
        }
    }

    applyFilter = (searchTerm, regionSelected, disciplineSelected) => {
        filterList = []
        if ((regionSelected === 'All' || regionSelected === "" || regionSelected === "Region") && (disciplineSelected === 'All' || disciplineSelected === "" || disciplineSelected === "Job domain")) {
            for (let i = 0; i < this.state.jobs.length; i++) {
                if (this.state.jobs[i].jobType.toUpperCase().includes(searchTerm.toUpperCase()) || this.state.jobs[i].companyName.toUpperCase().includes(searchTerm.toUpperCase())) {
                    filterList.push(this.state.jobs[i])
                    if (filterList.length !== 0) {
                        let totalPages = Math.ceil(filterList.length / this.state.perPage)
                        totalPages = [...Array(totalPages).keys()]
                        this.setState({totalPages: totalPages})
                    }
                }
            }
        } else if (regionSelected === 'All' || regionSelected === "" || regionSelected === "Region") {
            for (let i = 0; i < this.state.jobs.length; i++) {
                if ((this.state.jobs[i].jobType.toUpperCase().includes(searchTerm.toUpperCase()) || this.state.jobs[i].companyName.toUpperCase().includes(searchTerm.toUpperCase())) && this.state.jobs[i].discipline.toUpperCase().includes(disciplineSelected.toUpperCase())) {
                    filterList.push(this.state.jobs[i])
                    if (filterList.length !== 0) {
                        let totalPages = Math.ceil(filterList.length / this.state.perPage)
                        totalPages = [...Array(totalPages).keys()]
                        this.setState({totalPages: totalPages})
                    }
                }
            }
        } else if (disciplineSelected === 'All' || disciplineSelected === "" || disciplineSelected === "Job domain") {
            for (let i = 0; i < this.state.jobs.length; i++) {
                if ((this.state.jobs[i].jobType.toUpperCase().includes(searchTerm.toUpperCase()) || this.state.jobs[i].companyName.toUpperCase().includes(searchTerm.toUpperCase())) && this.state.jobs[i].region.toUpperCase().includes(regionSelected.toUpperCase())) {
                    filterList.push(this.state.jobs[i])
                    if (filterList.length !== 0) {
                        let totalPages = Math.ceil(filterList.length / this.state.perPage)
                        totalPages = [...Array(totalPages).keys()]
                        this.setState({totalPages: totalPages})
                    }
                }
            }
        } else for (let i = 0; i < this.state.jobs.length; i++) {
            if ((this.state.jobs[i].jobType.toUpperCase().includes(searchTerm.toUpperCase()) || this.state.jobs[i].companyName.toUpperCase().includes(searchTerm.toUpperCase())) && this.state.jobs[i].discipline.toUpperCase().includes(disciplineSelected.toUpperCase()) && this.state.jobs[i].region.toUpperCase().includes(regionSelected.toUpperCase())) {
                filterList.push(this.state.jobs[i])
                if (filterList.length !== 0) {
                    let totalPages = Math.ceil(filterList.length / this.state.perPage)
                    totalPages = [...Array(totalPages).keys()]
                    this.setState({totalPages: totalPages})
                }
            }
        }

        this.setState({searchToggle: true})
    }

    changePage(index) {
        this.props.dispatch(receiveIndexes({currentJobsPage: index}))
    }


    render() {

        if (this.state.searchToggle) {
            jobsList = filterList
        } else {
            jobsList = this.state.jobs
        }


        return (
            <React.Fragment>
                <Section applyFilter={this.applyFilter}/>
                {this.state.loader ?
                    <section className="section bg-white">
                        <div id="preloader">
                            <div id="status">
                                <div className="spinner">
                                    <div className="double-bounce1"></div>
                                    <div className="double-bounce2"></div>
                                </div>
                            </div>
                        </div>
                    </section> :
                    <section className="section bg-white">
                        <Container>
                            <Row>
                                <JobCardPublic
                                    jobs={jobsList.slice(this.state.perPage * this.props.indexes.currentJobsPage, (this.state.perPage * this.props.indexes.currentJobsPage) + this.state.perPage)}/>
                                <Col xs="12" className='pt-5'>
                                    <Pagination className="pagination justify-content-center mb-0  ">
                                        <PaginationItem><PaginationLink
                                            onClick={() => this.changePage(this.props.indexes.currentJobsPage - 1)}
                                            className="pr-3 pl-3 pt-2 pb-2 paginationJobs">Prev</PaginationLink></PaginationItem>
                                        {this.state.totalPages.map((index) =>
                                            <PaginationItem><PaginationLink onClick={() => this.changePage(index)}
                                                                            className={this.props.indexes.currentJobsPage === index ? 'active paginationJobs' : 'paginationJobs'}>{index + 1}</PaginationLink></PaginationItem>
                                        )}
                                        <PaginationItem><PaginationLink
                                            onClick={() => this.changePage(this.props.indexes.currentJobsPage + 1)}
                                            className="pr-3 pl-3 pt-2 pb-2 paginationJobs">Next</PaginationLink></PaginationItem>
                                    </Pagination>
                                </Col>
                            </Row>
                        </Container>
                    </section>}
            </React.Fragment>
        );
    }
}

function mapStateToProps({user, jobs, indexes}) {
    return {
        user, jobs,
        indexes
    }
}

export default withRouter(connect(mapStateToProps)(JobsPublic))
