import React, {Component, Suspense} from 'react';
import Layout from './components/Layout/';
import {BrowserRouter as Router, Route, Switch, withRouter} from 'react-router-dom';
import {createStore} from 'redux';
import {Provider} from 'react-redux';
import reducer from './reducers';
// Import Css
import './Apps.scss';
import ScrollToTop from './ScrollToTop';

import './css/materialdesignicons.min.css';
import './css/colors/default.css';
// Include Routes212
import LiveChat from 'react-livechat'

import routes from './routes';
import AuthProvider from "./pages/AuthProvider";
import * as Sentry from "@sentry/react";
export const store = createStore(reducer);

Sentry.init({
    dsn: "https://df51cd669ff44544acfd28e0f1597a58@o475258.ingest.sentry.io/5627914",
});
function withLayout(WrappedComponent) {
    // ...and returns another component...
    return class extends React.Component {
        render() {
            return <Layout>
                <WrappedComponent></WrappedComponent>
            </Layout>
        }
    };
}


class App extends Component {


    Loader = () => {
        return (
            <div id="preloader">
                <div id="status">
                    <div className="spinner">
                        <div className="double-bounce1"></div>
                        <div className="double-bounce2"></div>
                    </div>
                </div>
            </div>
        );
    }


    render() {

        return (
            <Provider store={store}>
                <React.Fragment>
                    <AuthProvider/>
                    <Router>
                        <ScrollToTop>
                            <Suspense fallback={this.Loader()}>
                                <LiveChat license={12675717} />

                                <Switch>
                                    {routes.map((route, idx) =>
                                        route.isWithoutLayout ?
                                            <Route path={route.path} component={route.component} key={idx}/>
                                            :
                                            <Route path={route.path} component={withLayout(route.component)} key={idx}/>
                                    )}
                                </Switch>
                            </Suspense>
                        </ScrollToTop>
                    </Router>
                </React.Fragment>
            </Provider>
        );
    }
}

export default withRouter(App)