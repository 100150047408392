import {RECEIVE_JOBS} from '../actions/jobs'

const initialState = [];

export default function connections(state = initialState, action) {
    switch (action.type) {
        case RECEIVE_JOBS :
            return action.jobs;
        default :
            return state
    }
}
