import {RECEIVE_USER} from '../actions/user'

const userObject = localStorage.getItem(
    'user',
);
const initialState = JSON.parse(userObject);


export default function user(state = initialState, action) {
    switch (action.type) {
        case RECEIVE_USER :
            return action.user;
        default :
            return state
    }
}
