import {RECEIVE_AUTH} from '../actions/auth'


const initialState = {authenticated: false};


export default function auth(state = initialState, action) {
    switch (action.type) {
        case RECEIVE_AUTH :
            return action.auth;
        default :
            return state
    }
}
