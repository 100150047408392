import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Badge, Form, FormGroup, Input, InputGroupAddon,InputGroupText, InputGroup  } from "reactstrap";
import './jobs.scss';
//React Typist
import Typist from 'react-typist';

//Import Images
import home from "../../images/job/home.png";

class Section extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchTerm: '',
            regionSelected:'',
            disciplineSelected:'',
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleEnter = this.handleEnter.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    clear = () => {
        const {applyFilter} = this.props
        this.setState({searchTerm:""})
        applyFilter("", this.state.regionSelected, this.state.disciplineSelected)
    }

    handleSubmit(e) {
        const {applyFilter} = this.props
        e.preventDefault();
        applyFilter(this.state.searchTerm, this.state.regionSelected, this.state.disciplineSelected)
    }

    handleEnter(e) {
        const {applyFilter} = this.props
        if (e.key === 13) {
            applyFilter(this.state.searchTerm, this.state.regionSelected, this.state.disciplineSelected)
        }
    }

    handleKeyPress(target) {
        const {applyFilter} = this.props
        if(target.charCode===13){
            console.log('Enter clicked!!!');
            applyFilter(this.state.searchTerm, this.state.regionSelected, this.state.disciplineSelected)
        }
    }

    render() {
        const {applyFilter} = this.props
        return (
            <React.Fragment>
                <section className="bg-half-170 pb-0 bg-white d-table w-100">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg="10">
                                <div className="title-heading text-center">
                                    <h1 className="heading mb-3 title-sans-jobs font-weight-bold">Discover a place you'll love to work.</h1>
                                    <div className="rounded p-4 mt-4 bg-light">
                                        <Row className="text-left">
                                            <Col lg="5" md="4">
                                                <InputGroup>
                                                        <Input type="text" required placeholder="Search term"
                                                               value={this.state.searchTerm}
                                                               id="search"
                                                               onSubmit='return false'
                                                               onKeyPress={target => this.handleKeyPress(target)}
                                                               onChange={event => this.setState({searchTerm: event.target.value})}>

                                                        </Input>
                                                    {this.state.searchTerm !== "" ?
                                                        <InputGroupAddon addonType="append">
                                                            <InputGroupText onClick={() => this.clear()} className='btn-primary'>x</InputGroupText>
                                                        </InputGroupAddon>: null }
                                                </InputGroup>
                                            </Col>
                                            <Col lg="7" md="8">
                                                <Row className="align-items-center">
                                                    <Col md="4" className="mt-4 mt-sm-0">
                                                        <FormGroup className="mb-0">
                                                            <select value={this.state.regionSelected}  onChange={event => this.setState({regionSelected: event.target.value})} className="form-control custom-select">
                                                                <option defaultValue="All">Region</option>
                                                                <option value="All">All</option>
                                                                <option value="East Flanders">East Flanders</option>
                                                                <option value="West Flanders">West Flanders</option>
                                                                <option value="Antwerp">Antwerp</option>
                                                                <option value="Limburg">Limburg</option>
                                                                <option value="Flemish Brabant">Flemish Brabant</option>
                                                                <option value="Brussels">Brussels</option>
                                                                <option value="Wallonia">Wallonia</option>
                                                                <option value="Netherlands">Netherlands</option>
                                                            </select>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4" className="mt-4 mt-sm-0">
                                                        <FormGroup className="mb-0">
                                                            <select value={this.state.disciplineSelected}  onChange={event => this.setState({disciplineSelected: event.target.value})} className="form-control custom-select">
                                                                <option defaultValue="All">Job domain</option>
                                                                <option value="All">All</option>
                                                                <option value="Administration">Administration</option>
                                                                <option value="Agriculture and horticulture">Agriculture and horticulture</option>
                                                                <option value="Communication">Communication</option>
                                                                <option value="Construction">Construction</option>
                                                                <option value="Creative">Creative</option>
                                                                <option value="Education">Education</option>
                                                                <option value="Financial">Financial</option>
                                                                <option value="Government">Government</option>
                                                                <option value="Health">Health</option>
                                                                <option value="Hospitality and tourism">Hospitality and tourism</option>
                                                                <option value="Human resources">Human resources</option>
                                                                <option value="IT">IT</option>
                                                                <option value="Legal">Legal</option>
                                                                <option value="Logistics and transport">Logistics and transport</option>
                                                                <option value="Maintenance">Maintenance</option>
                                                                <option value="Management">Management</option>
                                                                <option value="Marketing">Marketing</option>
                                                                <option value="Other">Other</option>
                                                                <option value="Production">Production</option>
                                                                <option value="Purchase">Purchase</option>
                                                                <option value="Research and development">Research and development</option>
                                                                <option value="Sales">Sales</option>
                                                                <option value="Services">Services</option>
                                                                <option value="Technic">Technic</option>
                                                            </select>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4" className="mt-4 mt-sm-0">
                                                        <Input onClick={() => applyFilter(this.state.searchTerm, this.state.regionSelected, this.state.disciplineSelected)} type="button" id="search" name="search" className="searchbtn btn btn-primary w-100 p" value="Search"/>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Section;